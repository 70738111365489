<template>
      <div class="row builder full-width full-height p-20">
            <div class="col-12 md:col-4">
                  <div class="builder-box">
                        <div class="top">
                              <Btn size="lg" class="align-self-center sm:align-self-flex-start" variant="primary" radius="0" title="sierra"/>
                              <h3 class="builder-box-title">
                                    Focusing on The Vision of Tomorrow
                              </h3>
                        </div>
                        <p class="explain text-justify sm:text-left mb-10">
                              Sierra has been crafting distinctive and beautiful homes for 35 years. From site selection and servicing to design and build, every stage of the development process is carefully and competently executed by their talented team. They have remained one of the best home builders in the GTA because they have consistently employed a rigid, and unwavering commitment to the ideal of excellence in craftmanship.
                        </p>
                  </div>
            </div>
            <div class="floor-images col-12 md:col-3">
                  <div class="image-container pt-30">
                        <div class="image-wrapper">
                              <img class="single-image pr-0" v-lazy-src="require(`@/assets/images/desktop/png/pages/builder/Sierra page .jpg`)" width="190px" alt="builder-5">
                              <p style="margin-left: calc(100% - 190px);">68 Main St.</p>
                        </div>
                        <div class="image-wrapper">
                              <img class="single-image" v-lazy-src="require(`@/assets/images/desktop/png/pages/builder/builder-3.jpg`)" width="190px" alt="builder-3">
                              <p style="margin-left: calc(100% - 190px);">68 Main St.</p>
                        </div>
                        <div class="image-wrapper">
                              <img class="single-image" v-lazy-src="require(`@/assets/images/desktop/png/pages/builder/builder-2.jpg`)" width="80%" alt="builder-2">
                              <p style="margin-left: calc(100% - 80%);">Platform Condos</p>
                        </div>
                  </div>
            </div>
            <div class="floor-images col-12 md:col-5">
                  <div class="image-container">
                        <div class="image-wrapper">
                              <img width="85%" class="single-image pb-0 pr-0" v-lazy-src="require(`@/assets/images/desktop/png/pages/builder/builder-6.jpg`)" alt="builder-4">
                              <p style="margin-left: calc(100% - 85%);">The Cardiff</p>
                        </div>
                        <div class="image-wrapper-double">
                              <div class="image-wrapper" style="width:40%;margin-right:15px">
                                    <img width="100%" class="single-image" v-lazy-src="require(`@/assets/images/desktop/png/pages/builder/builder-1.jpg`)" alt="builder-1">
                                    <p>The Chaplin</p>
                              </div>
                              <div class="image-wrapper" style="width:40%">
                                    <img width="90%" class="single-image pb-0" v-lazy-src="require(`@/assets/images/desktop/png/pages/builder/builder-4.jpg`)" alt="builder-4">
                                    <p style="margin-left: calc(100% - 90%);">Dellwood Park</p>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</template>

<script>
      import Btn from '@/components/base/button/Button';
      import directive from '@/directives/lazy-src';
      export default {
            components: {Btn},
            name: 'builder',
            directives: {directive},
            data() {
                  return {
                        images: [
                              {name: "builder-1"},
                              {name: "builder-2"},
                              {name: "builder-3"},
                              {name: "builder-4"},
                              {name: "builder-5"},
                              {name: "builder-6"},
                        ]
                  }
            },
            mounted(){
                  if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 991px)").matches)) {
                        document.querySelector('body').style.overflowY = 'scroll';
                  }
                  window.addEventListener('resize', this.resizeHandler)
            },
            beforeDestroy(){
                  if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 991px)").matches)) {
                        document.querySelector('body').style.overflowY = 'hidden';
                  }
                  window.removeEventListener('resize', function(){})
            },
            methods:{
                  resizeHandler(){
                        if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 991px)").matches)) {
                              document.querySelector('body').style.overflowY = 'scroll';
                        } else {
                              document.querySelector('body').style.overflowY = 'hidden';
                        }
                  }
            }
      }
</script>

<style lang='scss'>
@import 'Builder';
</style>