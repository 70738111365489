<template>
      <div class="builder-page">
            <Builder/>
      </div>
</template>

<script>
      import Builder from '@cp/builder/Builder'
      export default {
            name: 'builder',
            components: {Builder}
      }
</script>

<style lang='scss'>
.builder-page{
      border-top: 1px solid $mainBorderColor;
}
</style>